<template>
    <div class="activity-wrap">
        <Heaeder :title="title" :showBack="true" @return="doBack()" />

        <div v-if="requestEnd && !freeActivtyDate.active" class="free-activity end">
            <div class="end-title">活动已结束</div>
            <div class="end-tips">敬请期待其他活动</div>
        </div>
        <div ref="free-activity" v-else-if="requestEnd" class="free-activity">
            <div class="banner"></div>
            <div class="time-desc">
                限免时间：{{ freeActivtyDate.startTime | formatDate('yyyy-MM-dd hh:mm') }}至{{
                    freeActivtyDate.endTime | formatDate('yyyy-MM-dd hh:mm')
                }}
            </div>
            <div class="comic-list">
                <div
                    @click="toComicRead(comic)"
                    v-for="(comic, index) in freeActivtyDate.topics"
                    :key="comic.comicTopicId"
                    class="comic-item"
                >
                    <div class="comic-img">
                        <img
                            v-if="index < 12"
                            :src="(host + comic.topicImgUrl) | compress(234)"
                            alt=""
                        />
                        <img
                            v-else
                            v-lazyLoad
                            :data-src="(host + comic.topicImgUrl) | compress(234)"
                            alt=""
                        />
                        <div class="tips">前{{ comic.limitedFreeComicCount }}章限免</div>
                    </div>
                    <div class="comic-name">{{ comic.topicAliasOrName }}</div>
                    <div class="comic-lable">
                        <span class="lable-item" v-for="lable in comic.labels" :key="lable">
                            {{ lable }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Heaeder from '@/components/header/index.vue';
import Controller from './Controller.js';
import { query } from '@kk/utils';
import jump from '@/views/Index/jump.js';

export default {
    name: 'FreeActivity',
    data: function () {
        return {
            requestEnd: false,
            scrollDom: null,
            scrollTop: 0,
            from: '',
        };
    },
    components: {
        Heaeder,
    },
    computed: {
        freeActivtyDate() {
            return Controller.freeActivtyDate;
        },
        host() {
            return this.freeActivtyDate.host;
        },
        title() {
            return this.requestEnd ? this.freeActivtyDate.configName || '限免活动' : '';
        },
    },
    methods: {
        doBack() {
            this.$router.replace({
                name: 'Index',
            });
        },
        toComicRead(comic) {
            // 含有续读专题
            if (comic.continueChapter && comic.continueChapter.chapterId) {
                this.$router.push({
                    name: 'ComicRead',
                    query: {
                        comicId: comic.continueChapter.chapterId,
                        topicId: comic.comicTopicId,
                    },
                    params: { TriggerPage: '限免活动页', SourceModule: '限免活动页' },
                });
                return;
            }
            jump(
                {
                    type: 3002,
                    target_id: comic.comicTopicId,
                },
                {
                    params: { TriggerPage: '限免活动页', SourceModule: '限免活动页' },
                }
            );
        },
    },
    beforeRouteLeave(to, from, next) {
        if (to.name === 'ComicRead' && this.freeActivtyDate.active) {
            this.scrollTop = this.$refs['free-activity']
                ? this.$refs['free-activity'].scrollTop
                : 0;
        }
        next();
    },
    activated() {
        this.sa('CommonPageOpen', {
            uid: this.$store.state.userInfo.uid,
            CurPage: '限免活动页',
            PrePage: this.from || query.get('origin'),
        });
        if (this.requestEnd) {
            this.$nextTick(() => {
                this.$refs['free-activity'] &&
                    this.$refs['free-activity'].scrollTo(0, this.scrollTop);
            });
        }
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.from = from.meta.trackName;
        });
    },
    async created() {
        await Controller.getFreeActivity();
        loading.hide();
        this.requestEnd = true;
    },
};
</script>
<style scoped>
.activity-wrap {
    height: 100vh;
}
.free-activity {
    overflow-y: scroll;
    padding: 0 2vw 10vw;
    height: calc(100% - 48px);
    animation: show 0.4s forwards ease-in;
}
.free-activity.end {
    box-sizing: border-box;
    padding-top: 67.3vw;
    background: url('./empty.png') center 34vw / 24.4vw 33.4vw no-repeat;
}
@keyframes show {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.end-title {
    margin-top: 24px;
    font-size: 16px;
    text-align: center;
    color: #f9b4b4;
}
.end-tips {
    margin-top: 6px;
    font-size: 14px;
    text-align: center;
    color: #f9b4b4;
}
.banner {
    margin: 0 auto;
    width: 95.7vw;
    height: 34.7vw;
    background: url('https://f2.kkmh.com/game/210830/8Z54gKovn.png') center/100%;
}
.time-desc {
    margin: 15px 0;
    font-size: 12px;
    text-align: center;
    color: #333;
    line-height: 15px;
}
.comic-list {
    font-size: 0;
}
.comic-item {
    display: inline-block;
    width: 31.2vw;
    vertical-align: top;
}
.comic-item:nth-child(3n + 2) {
    margin: 0 1.2vw;
}
.comic-name {
    overflow: hidden;
    margin: 10px 0 7px;
    font-size: 15px;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #333;
    line-height: 15px;
}
.comic-lable {
    overflow: hidden;
    margin-bottom: 12px;
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #999;
    line-height: 12px;
}
.lable-item {
    margin-right: 4px;
}
.lable-item:last-child {
    margin-right: 0;
}
.comic-img {
    position: relative;
    width: 100%;
    height: 41.3vw;
}
.comic-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.tips {
    position: absolute;
    top: 0;
    right: 0;
    padding: 4px 5px;
    font-size: 10px;
    border-radius: 0 0 0 5px;
    color: #fff;
    background: linear-gradient(135deg, #ff4f4a 0%, #f44 100%);
    line-height: 10px;
}
.isAndroid .tips {
    padding: 8px 10px;
    font-size: 20px;
    border-radius: 0 0 0 10px;
    white-space: nowrap;
    line-height: 20px;
    transform: scale(0.5);
    transform-origin: right top;
}
</style>
