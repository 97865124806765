import Vue from 'vue';
import { get } from '@/modules/request';

// https://cn.vuejs.org/v2/api/#Vue-observable
// 最小化的跨组件状态存储器
// Vue.obserbale返回值可直接用于渲染函数和计算属性
const freeActivtyDate = Vue.observable({
    startTime: 0,
    endTime: 0,
    topics: [],
    configName: '',
    host: '',
    active: false,
});

const getFreeActivity = function () {
    return get({
        url: process.env.VUE_APP_DISTRIBUTION_HOST + '/distribution/limited_free',
    })
        .then((res) => {
            const { configName, endTime, startTime, topics = [] } = res.data;
            freeActivtyDate.startTime = startTime;
            freeActivtyDate.endTime = endTime;
            freeActivtyDate.topics = topics.map((topic) => {
                return {
                    ...topic,
                    topicAliasOrName: topic.topicAlias || topic.topicName,
                };
            });
            freeActivtyDate.configName = configName;
            freeActivtyDate.host = res.host;
            freeActivtyDate.active = true;
            return res;
        })
        .catch(() => {
            freeActivtyDate.active = false;
        });
};

export default {
    freeActivtyDate,
    getFreeActivity,
};
